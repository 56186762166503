import { useState } from 'react';

const Search = ({ onSearchChange }) => {
    const [searchQuery, setSearchQuery] = useState('');

    const handleChange = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
        onSearchChange(query);
    };
    
    return (
        <div className="flex justify-end items-center">
            <input
                className="w-full md:w-1/3 py-3 pl-12 pr-4 text-coolGray-900 leading-tight placeholder-coolGray-500 border border-coolGray-200 rounded-lg shadow-xsm focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-opacity-50 ml-auto" 
                type="text" 
                placeholder="Search"
                value={searchQuery}
                onChange={handleChange}
            />
            {/* <img className="ml-2" src="../assets/flex-ui-assets/elements/blog/search.svg" alt="Search icon" /> */}
        </div>
    )
}

export default Search