import PerkCard from './PerkCard'

const PerkList = ({ perks, excludedPerks, isEmbedded, handleView }) => {
  return (
    perks.map(perk => (
      <PerkCard
        key={perk.id}
        perk={perk}
        excluded={excludedPerks?.includes(perk.id) || false}
        isEmbedded={isEmbedded}
        handleView={handleView}
      />
    ))
  )
} 

export default PerkList;
