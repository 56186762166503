import React, { useState, useContext, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useAuth } from "../contexts/AuthProvider";
import ResourceContext from "../contexts/ResourceContext";
import ProfileContext from "../contexts/ProfileContext";
import { supabase } from "../services/supabase/supabaseClient";

import SideNav from "../components/SideNav";
import Perks from "../components/views/Perks";
import PerkDetails from "../components/views/PerkDetails";
import Investors from "../components/views/Investors";
import InvestorDetails from "../components/views/InvestorDetails";
import Events from "../components/views/Events";
import EventDetails from "../components/views/EventDetails";
import ProfileView from "../components/views/ProfileView";
import EmbedView from "../components/views/EmbedView";
import ActivityDashboardView from "../components/views/Dashboards/ActivityDashboardView";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  const { user, signOut } = useAuth();
  const [resource, resourceDispatch] = useContext(ResourceContext);
  const [profile, profileDispatch] = useContext(ProfileContext);
  const [isSignOutSuccessful, setSignOutSuccessful] = useState(false);
  const [highlightedItem, setHighlightedItem] = useState("Perks");
  const [submissions, setSubmissions] = useState([]);

  useEffect(() => {
    const fetchFormSubmissions = async () => {
      if (!profile || !profile.profile || !profile.profile.embed_id) {
        return;
      }

      // Fetch perk submissions related to the user's embed_id
      const { data: submissionsData, error: submissionsError } = await supabase
        .from("submissions")
        .select("email, form_id, created_at")
        .eq("embed_id", profile.profile.embed_id)
        .eq("form_type", "perk")
        .order("created_at", { ascending: false });

      if (submissionsError) {
        console.error("Error fetching submissions:", submissionsError);
        return;
      }

      // Extract form_ids from submissions to use in perks fetch
      const formIds = submissionsData.map((submission) => submission.form_id);

      // Fetch relevant perks where hubspot_form_id matches submissions' form_id
      const { data: perksData, error: perksError } = await supabase
        .from("perks")
        .select("hubspot_form_id, name, perk, value, icon_url")
        .in("hubspot_form_id", formIds); // Using 'in' to match multiple form_ids

      if (perksError) {
        console.error("Error fetching perks:", perksError);
        return;
      }

      // Merge submissions with matching perks data and format created_at
      const submissionsWithPerks = submissionsData.map((submission) => {
        const matchingPerk = perksData.find(
          (perk) => perk.hubspot_form_id === submission.form_id
        );

        // Format created_at to MM/DD/YYYY
        const formattedDate = new Date(
          submission.created_at
        ).toLocaleDateString("en-US", {
          month: "numeric",
          day: "numeric",
          year: "numeric",
        });

        return {
          ...submission,
          created_at: formattedDate, // Use formatted date
          ...matchingPerk, // Merge matching perk fields if found
        };
      });

      setSubmissions(submissionsWithPerks);
    };

    fetchFormSubmissions();
  }, [profile]);

  const handleItemClick = (item) => {
    setHighlightedItem(item);
  };

  const handleSignOut = async (event) => {
    event.preventDefault();

    try {
      await signOut();
      setSignOutSuccessful(true);
    } catch (error) {
      console.log(`error signing out`);
    }
  };

  if (isSignOutSuccessful) {
    return <Navigate to="/login" />;
  }

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <section className="overflow-hidden min-h-full">
        <SideNav
          email={user.email}
          handleSignOut={handleSignOut}
          highlightedItem={highlightedItem}
          handleItemClick={handleItemClick}
        />
        {highlightedItem === "Perks" ? (
          resource.type === "Perks" ? (
            <Perks />
          ) : (
            <PerkDetails />
          )
        ) : highlightedItem === "Investors" ? (
          resource.type === "Investors" ? (
            <Investors />
          ) : (
            <InvestorDetails />
          )
        ) : highlightedItem === "Events" ? (
          resource.type === "Events" ? (
            <Events />
          ) : (
            <EventDetails />
          )
        ) : highlightedItem === "Profile" ? (
          <ProfileView />
        ) : highlightedItem === "Embed" ? (
          <EmbedView />
        ) : highlightedItem === "Analytics" ? (
          <ActivityDashboardView redemptions={submissions} />
        ) : (
          <div>Loading...</div>
        )}
      </section>
    </React.Fragment>
  );
}
