import { createContext, useReducer } from 'react';

const FlagContext = createContext()

const flagReducer = (state, action) => {
    switch(action.type) {
        case 'SET_FLAGS': {
            return action.payload
        }
        default: {
            return null
        }
    }
}

export const FlagContextProvider = (props) => {
    const [flags, flagDispatch] = useReducer(flagReducer, null)

    return (
        <FlagContext.Provider value={[flags, flagDispatch]}>
            {props.children}
        </FlagContext.Provider>
    )
}

export default FlagContext;