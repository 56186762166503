import React, { useEffect, useContext, useState } from "react";
import { useLocation } from 'react-router-dom';
import ProfileContext from '../contexts/ProfileContext';
import { supabase } from '../services/supabase/supabaseClient';
    
const HubspotContactForm = props => {
    const { formId, formType, redirectUrl } = props;
    const location = useLocation();
    const [profile, profileDispatch] = useContext(ProfileContext)

    const getEmbedId = () => {
        let embedId;
        let source;

        // first check for embed, because context could exist
        // embedded submission
        if (window.self !== window.top) {
            const src = window.location.href;
            const iframeMatch = src.match(/^https?:\/\/.+?\/embed\/([^\/?#]+)/);
            if (iframeMatch) {
                source = 'embed';
                embedId = iframeMatch[1];
                return { source, embedId };
            }
        }

        // if not in iframe, check if share link
        // share link submission
        const sharelinkMatch = location.pathname.match(/^\/embed\/([^\/?#]+)/);
        if (sharelinkMatch) {
            source = 'link'
            embedId = sharelinkMatch[1];
            return { source, embedId };
        }

        // if not share link, check if app
        // in-app submission
        if (profile && profile.profile) {
            // Check if embed_id exists
            if (profile.profile.embed_id) {
                source = 'app'
                embedId = profile.profile.embed_id;
                return { source, embedId };
            }
        }

        // If none of the above conditions are met, return null
        return { source, embedId };
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/shell.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    region: 'na1',
                    portalId: '43157306',
                    formId: formId,
                    target: '#hubspotForm',
                    onFormSubmit: async (e) => {
                        const email = e.elements.namedItem('email').value
                        const data = getEmbedId()
                        await supabase
                            .from('submissions')
                            .insert({ form_type: formType, source: data.source, form_id: formId, embed_id: data.embedId, email: email })

                        // if perk uses a redirect, manage that here
                        if (redirectUrl) {
                            window.top.location.href = redirectUrl
                        }
                    }
                })
            }
        });
    }, [formId, profile]);

    return (
        <div>
            <div id="hubspotForm"></div>
        </div>
    );
};

export default HubspotContactForm;