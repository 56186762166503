import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useState } from 'react'
import { supabase } from '../services/supabase/supabaseClient';
import { useNavigate } from 'react-router-dom';

import Error from '../components/Error'

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function ResetPassword() {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showLoginButton, setShowLoginButton] = useState(false)
  const [error, setError] = useState(null)
  const [buttonText, setButtonText] = useState('Reset')
  const navigate = useNavigate()

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (password != confirmPassword) {
        setError('Error: Passwords must match')
        return setTimeout(() => {
            setError(null)
        }, 2000);
    }

    if (password.length < 6) {
        setError('Error: Password must be at least 6 characters')
        return setTimeout(() => {
            setError(null)
        }, 2000);
    }

    await supabase.auth.updateUser({ password: password })

    setButtonText('Password Reset!')
    setShowLoginButton(true)
    setTimeout(() => {
        setButtonText('Reset')
    }, 2000);
  }

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <section
        className='py-24 md:py-32 bg-white'
        style={{
          backgroundImage: 'url("flex-ui-assets/elements/pattern-white.svg")',
          backgroundPosition: 'center',
        }}
      >
        <div className='container px-4 mx-auto'>
          <div className='max-w-sm mx-auto'>
            <div className='mb-6 text-center'>
              <a className='inline-block mb-6' href='#'>
                <img
                  className='h-16'
                  src='flex-ui-assets/logos/Launchbook_Logo.svg'
                  alt=''
                />
              </a>
              <h3 className='mb-4 text-2xl md:text-3xl font-bold'>
                Reset your password
              </h3>
              <p className='text-lg text-coolGray-500 font-medium'>
                Enter a new password below.
              </p>
            </div>
            <form onSubmit={handleSubmit}> 
              <div className='mb-6'>
                <label
                  className='block mb-2 text-coolGray-800 font-medium'
                  htmlFor=''
                >
                  Password*
                </label>
                <input
                  className='appearance-none block w-full p-3 leading-5 text-coolGray-900 border border-coolGray-200 rounded-lg shadow-md placeholder-coolGray-400 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-opacity-50'
                  type='password'
                  placeholder='**********'
                  value={password}
                  onChange={({ target }) => setPassword(target.value)}
                  required
                />
              </div>
              <div className='mb-6'>
                <label
                  className='block mb-2 text-coolGray-800 font-medium'
                  htmlFor=''
                >
                  Confirm Password*
                </label>
                <input
                  className='appearance-none block w-full p-3 leading-5 text-coolGray-900 border border-coolGray-200 rounded-lg shadow-md placeholder-coolGray-400 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-opacity-50'
                  type='password'
                  placeholder='**********'
                  value={confirmPassword}
                  onChange={({ target }) => setConfirmPassword(target.value)}
                  required
                />
              </div>
              <Error message={error}/>
              <button
                className='inline-block py-3 px-7 mb-4 w-full text-base text-violet-50 font-medium text-center leading-6 bg-violet-500 hover:bg-violet-600 focus:ring-2 focus:ring-violet-500 focus:ring-opacity-50 rounded-md shadow-sm'
                href='#'
                type='submit'
              >
                {buttonText}
              </button>
            </form>
            <form>
            <button
                style={{ display: showLoginButton ? 'block' : 'none' }}
                className='inline-block py-3 px-7 mb-4 w-full text-base text-violet-50 font-medium text-center leading-6 bg-violet-500 hover:bg-violet-600 focus:ring-2 focus:ring-violet-500 focus:ring-opacity-50 rounded-md shadow-sm'
                href='#'
                onClick={() => navigate('/login')}
              >
                Login
              </button>
            </form>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

