import HubspotContactForm from './HubSpotForm';

const EditPerkCard = () => {

    const customComponents = {
        // Overriding of component styles for react-markdown
        p: ({ node, ...props }) => <p style={{ margin: '1em 0' }} {...props} />,
        li: ({ node, ...props }) => <li style={{ marginLeft: '1em', counterIncrement: 'my-counter' }} {...props} />,
        ol: ({ node, ...props }) => <ol style={{ listStyleType: 'decimal', counterReset: 'my-counter' }} {...props} />,
        ul: ({ node, ...props }) => <ul style={{ listStyleType: 'disc', paddingLeft: '1em' }} {...props} />,
    };

    return (
        <div className='w-full md:w-1/2 p-3 flex' style={{ margin: 'auto', overflow: 'visible' }}>
            <div className='bg-white border border-coolGray-100 shadow-dashboard rounded-md'>
                <div className='flex flex-col px-4 pt-8 pb-6 border-b border-coolGray-100' style={{ height: '100%' }}>
                    <div className='flex justify-center'>
                        <h2 className='text-lg font-medium text-coolGray-900'>
                            Edit your perk
                        </h2>
                    </div>
                    <div>
                        <p>We'd like to express our sincere appreciation for your support of the Global Venture Network community. If you'd like to make edits to your perk, please submit the form below. We aim to publish edits within 24 hours. Thank you for your commitment to supporting startups!</p>
                        <p></p>
                    </div>
                    <HubspotContactForm formId='336cab78-e930-4e04-b325-dda2acecdd02' formType='edit-perk'/>
                </div>
            </div>
        </div>
    )
}

export default EditPerkCard;
