const ActivityDataTableRow = ({
  email,
  redeemedAt,
  perk,
  perkOffering,
  estValue,
  iconUrl,
}) => {
  return (
    <tr className="h-18 border-b border-coolGray-100">
      <th className="whitespace-nowrap px-4 bg-white text-left">
        <div className="flex items-center -m-2">
          <div className="w-auto p-2">
            <div className="flex items-center justify-center w-10 h-10 text-base font-medium text-violet-600 rounded-md">
              <img src={iconUrl} />
            </div>
          </div>
          <div className="w-auto p-2">
            <p className="text-sm font-medium text-coolGray-500">{perk}</p>
          </div>
        </div>
      </th>
      <th className="whitespace-nowrap px-4 bg-white text-sm font-medium text-coolGray-800 text-left">
        {email}
      </th>
      <th className="whitespace-nowrap px-4 bg-white text-sm font-medium text-coolGray-800 text-left">
        {redeemedAt}
      </th>
      <th className="whitespace-nowrap px-4 bg-white text-sm font-medium text-coolGray-800 text-left">
        {perkOffering}
      </th>
      <th className="whitespace-nowrap px-4 bg-white text-sm font-medium text-coolGray-800 text-left">
        {Number(estValue)
          ? Number(estValue).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })
          : "$0"}
      </th>
      <th className="whitespace-nowrap pr-4 bg-white text-sm font-medium text-coolGray-800"></th>
    </tr>
  );
};

export default ActivityDataTableRow;
