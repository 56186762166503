import ContactDataTableRow from './ContactDataTableRow'

const ContactRowList = ({ contacts }) => {
    return (
        contacts.map((contact, index) => (
            <ContactDataTableRow
                key={`${contact.email}-${index}`}
                redeemedAt={contact.submittedAt}
                name={contact.firstName + ' ' + contact.lastName}
                initials={contact.firstName.charAt(0) + contact.lastName.charAt(0)}
                email={contact.email}
                company={contact.company}
                country={contact.country}
                founded={contact.yearFounded}
                teamSize={contact.teamSize}
                networkPartner={contact.networkPartner}
            />
        ))
    )
}

export default ContactRowList;