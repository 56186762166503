import { useContext } from "react";
import CopyableTag from "./CopyableTag";
import ResourceContext from "../contexts/ResourceContext";
import FlagContext from "../contexts/FlagContext";
import HubspotContactForm from "./HubSpotForm";
import ReactMarkdown from "react-markdown";

const PerkDetailsCard = ({ embedId }) => {
  const [resource, resourceDispatch] = useContext(ResourceContext);
  const [flags, flagDispatch] = useContext(FlagContext);
  const perk = resource.resource;

  const shareLinksFlag = flags?.flags.some(
    (flag) => flag.flag_id === "b8527cc6-0cc0-4675-9ca5-b56dc9e178e0"
  );

  if (!perk) {
    return;
  }

  const customComponents = {
    // Overriding of component styles for react-markdown
    p: ({ node, ...props }) => <p style={{ margin: "1em 0" }} {...props} />,
    li: ({ node, ...props }) => (
      <li
        style={{ marginLeft: "1em", counterIncrement: "my-counter" }}
        {...props}
      />
    ),
    ol: ({ node, ...props }) => (
      <ol
        style={{ listStyleType: "decimal", counterReset: "my-counter" }}
        {...props}
      />
    ),
    ul: ({ node, ...props }) => (
      <ul style={{ listStyleType: "disc", paddingLeft: "1em" }} {...props} />
    ),
  };

  return (
    <div
      className="w-full md:w-1/2 p-3 flex"
      style={{ margin: "auto", overflow: "visible" }}
    >
      <div className="bg-white border border-coolGray-100 shadow-dashboard rounded-md">
        <div
          className="flex flex-col px-4 pt-8 pb-6 border-b border-coolGray-100"
          style={{ height: "100%" }}
        >
          {shareLinksFlag && (
            <CopyableTag
              embedId={embedId}
              resourceType={"perk"}
              resourceId={perk.id}
            />
          )}
          <div className="flex justify-center">
            <img
              style={{ paddingTop: "20px" }}
              className="mb-4"
              src={perk.icon_url}
              alt=""
            />
          </div>
          <div className="flex justify-center">
            <h2 className="text-lg font-medium text-coolGray-900">
              {perk.name}
            </h2>
          </div>
          <div>
            <ReactMarkdown components={customComponents}>
              {perk.description}
            </ReactMarkdown>
          </div>
          <div
            className="perk-info"
            style={{
              marginTop: "auto",
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
          >
            <div>
              <h2 style={{ display: "inline-block" }}>
                <strong>Offer:&nbsp;</strong>
              </h2>
              <h2 style={{ display: "inline-block" }}>{perk.perk}</h2>
            </div>
            <div>
              <h2 style={{ display: "inline-block" }}>
                <strong>Est. Value:&nbsp;</strong>
              </h2>
              <h2 style={{ display: "inline-block" }}>
                {Number(perk.value).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </h2>
            </div>
          </div>
          <HubspotContactForm
            formId={perk.hubspot_form_id}
            formType="perk"
            redirectUrl={perk.redirect_url}
          />
        </div>
      </div>
    </div>
  );
};

export default PerkDetailsCard;
