import React, { createContext, useContext, useEffect, useState } from "react";

import { supabase } from "../services/supabase/supabaseClient";
import ProfileContext from "./ProfileContext";
import FlagContext from "./FlagContext";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [profile, profileDispatch] = useContext(ProfileContext);
  const [flags, flagDispatch] = useContext(FlagContext);
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getSession = async () => {
      const {
        data: { session },
        error,
      } = await supabase.auth.getSession();
      if (error) throw error;

      if (session?.user?.id) {
        // Check if session.user.id exists
        // Get profile data
        const { data: profile, error: profileError } = await supabase
          .from("profiles")
          .select(
            "first_name, last_name, organization_name, embed_id, is_corporate, perk_form_id"
          )
          .eq("id", session.user.id)
          .single();

        if (profileError) throw profileError;

        // Get the max end_date and corresponding product name for the user
        const { data: subscriptionData, error: subscriptionError } =
          await supabase
            .from("subscriptions")
            .select("end_date, product_id, stripe_customer_id")
            .eq("user_id", session.user.id)
            .order("end_date", { ascending: false })
            .limit(1)
            .maybeSingle();

        if (subscriptionError) throw subscriptionError;

        let productName = null;

        if (subscriptionData?.product_id) {
          // Get the product name based on product_id
          const { data: productData, error: productError } = await supabase
            .from("products")
            .select("name")
            .eq("id", subscriptionData.product_id)
            .single();

          if (productError) throw productError;

          productName = productData?.name;
        }

        profileDispatch({
          type: "SET_PROFILE",
          payload: {
            profile: {
              ...profile,
              subscription_end_date: subscriptionData?.end_date,
              subscription_product_name: productName,
              stripe_customer_id: subscriptionData?.stripe_customer_id,
            },
          },
        });

        // Get flag data
        const { data: flagData, error: flagError } = await supabase
          .from("flag_users")
          .select("flag_id")
          .eq("user_id", session.user.id);

        flagDispatch({
          type: "SET_FLAGS",
          payload: {
            flags: flagData,
          },
        });
      }
      setLoading(false);
    };

    const { data: listener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        setSession(session);
        setUser(session?.user);
        setLoading(false);
      }
    );

    getSession();

    return () => {
      listener?.subscription.unsubscribe();
    };
  }, []);

  const fetchProfile = async (userId) => {
    // utility function for SET_PROFILE dispatch on signIn and signUp
    const { data: profileData, error: profileError } = await supabase
      .from("profiles")
      .select(
        "first_name, last_name, organization_name, embed_id, is_corporate, perk_form_id"
      )
      .eq("id", userId)
      .single();

    if (profileError) throw profileError;

    // Get the max end_date and corresponding product name for the user
    const { data: subscriptionData, error: subscriptionError } = await supabase
      .from("subscriptions")
      .select("end_date, product_id, stripe_customer_id")
      .eq("user_id", userId)
      .order("end_date", { ascending: false })
      .limit(1)
      .maybeSingle();

    if (subscriptionError) throw subscriptionError;

    let productName = null;

    if (subscriptionData?.product_id) {
      // Get the product name based on product_id
      const { data: productData, error: productError } = await supabase
        .from("products")
        .select("name")
        .eq("id", subscriptionData.product_id)
        .single();

      if (productError) throw productError;

      productName = productData?.name;
    }

    profileDispatch({
      type: "SET_PROFILE",
      payload: {
        profile: {
          ...profileData,
          subscription_end_date: subscriptionData?.end_date,
          subscription_product_name: productName,
          stripe_customer_id: subscriptionData?.stripe_customer_id,
        },
      },
    });
  };

  const fetchFlags = async (userId) => {
    // utility function for SET_FLAGS dispatch on signIn
    const { data: flagData, error: flagError } = await supabase
      .from("flag_users")
      .select("flag_id")
      .eq("user_id", userId);

    flagDispatch({
      type: "SET_FLAGS",
      payload: {
        flags: flagData,
      },
    });
  };

  const value = {
    session,
    user,
    signUp: async (email, password, isCorporate) => {
      if (isCorporate) {
        // check if email is in allowed_corporates
        const { data, allowedError } = await supabase.rpc(
          "check_corporate_email",
          { u_email: email }
        );
        if (allowedError) {
          throw allowedError;
        }
        // data will be a perk_form_id, if it exists
        // failing cases will null, or potentially an empty string
        if (!data || data.trim() === "") {
          throw new Error(
            "Oops! We don't recognize your email. You can <b><a href='https://meetings.hubspot.com/john2497/partnership-discovery'>schedule a call here</b> to join the waitlist</a>."
          );
        }

        // create user with isCorporate and perk_form_id to pass through to profile
        const { data: userData, error } = await supabase.auth.signUp({
          email,
          password,
          options: { data: { is_corporate: true, perk_form_id: data } },
        });
        if (error) throw error;
        fetchProfile(userData.user.id);

        return userData.user;
      }

      // Call function to check if email is allowed
      const { data, allowedError } = await supabase.rpc("check_allowed_email", {
        u_email: email,
      });
      if (allowedError) {
        throw allowedError;
      }
      // data returns either true or false
      if (!data || data.length === 0) {
        throw new Error(
          "Oops! We don't recognize your email. You can <b><a href='https://globalventurenetwork.com/join-the-network'>join the waitlist here</a></b>."
        );
      }

      // Extract the allowed email data
      const allowedEmailData = data[0];

      // on NP signup, insert relevant organization data into Profiles table
      const { user, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            organization_name: allowedEmailData.organization_name,
            organization_type: allowedEmailData.organization_type,
            image_url: allowedEmailData.image_url,
            network_size: allowedEmailData.network_size,
            country: allowedEmailData.country,
            state_us_only: allowedEmailData.state_us_only,
            city: allowedEmailData.city,
          },
        },
      });
      if (error) throw error;
      return user;
    },
    signIn: async (email, password) => {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) throw error;

      // SET_PROFILE dispatch on signIn
      fetchProfile(data.user.id);

      // SET_FLAGS dispatch on signIn
      fetchFlags(data.user.id);

      return data;
    },
    signInWithGoogle: async () => {
      const { user, error } = await supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
          // redirect to oauth which will confirm if full user
          // profile exists
          redirectTo: `http://localhost:3000/`,

          // this will force you to select your google account
          // every time, instead of google recognizing you
          //     queryParams: {
          //         prompt: 'select_account'
          //     }
        },
      });
      if (error) throw error;

      return user;
    },
    signOut: async () => {
      await supabase.auth.signOut();
    },
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
