import { Routes, Route } from 'react-router-dom'

import IndexPage from './pages/Index.js'
import ProtectedRoute from './components/ProtectedRoute.jsx'
import ForgotPasswordPage from './pages/ForgotPassword.js'
import ResetPasswordPage from './pages/ResetPassword.js'
import LoginPage from './pages/Login.js'
import PartnerLoginPage from './pages/PartnerLogin.js'
import PartnerIndex from './pages/PartnerIndex.js'
import Embedpage from './pages/Embed.js'
import { useAuth } from './contexts/AuthProvider.js'


const App = () => {
  const { user } = useAuth()
  const isCorporate = user?.user_metadata.is_corporate

  return (
    <Routes>
      <Route path="/login" element={<LoginPage/>}/>
      <Route path="/partners" element={<PartnerLoginPage/>}/>
      <Route path="/forgot-password" element={<ForgotPasswordPage/>}/>
      <Route path="/embed/:embed_id" element={<Embedpage />} />
      <Route
        path="/reset-password"
        element={
          <ProtectedRoute>
            <ResetPasswordPage/>
          </ProtectedRoute>
        }
      />
      <Route
        path="/"
        element={
          !user ? (
            <LoginPage />
          ) : (
            <ProtectedRoute>
              {isCorporate ? <PartnerIndex /> : <IndexPage />}
            </ProtectedRoute>
          )
        }
      />
    </Routes>
  );
}

export default App
