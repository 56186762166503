import ActivityDataTableRow from "./ActivityDataTableRow";

const ActivityRowList = ({ redemptions }) => {
  return redemptions.map((redemption, index) => (
    <ActivityDataTableRow
      key={`${redemption.email}-${index}`}
      redeemedAt={redemption.created_at}
      email={redemption.email}
      perk={redemption.name}
      perkOffering={redemption.perk}
      estValue={redemption.value}
      iconUrl={redemption.icon_url}
    />
  ));
};

export default ActivityRowList;
