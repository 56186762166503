import { createContext, useReducer } from 'react';

const ResourceContext = createContext()

const resourceReducer = (state, action) => {
    switch(action.type) {
        case 'setResource': {
            return action.payload
        }
        case 'removeResource': {
            return null
        }
        default: {
            return null
        }
    }
}

export const ResourceContextProvider = (props) => {
    const [resource, resourceDispatch] = useReducer(resourceReducer, { type: 'Perks' })

    return (
        <ResourceContext.Provider value={[resource, resourceDispatch]}>
            {props.children}
        </ResourceContext.Provider>
    )
}

export default ResourceContext;