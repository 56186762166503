import PerkDetailsCard from "../PerkDetailsCard";
import { useContext, useEffect } from "react";
import ResourceContext from "../../contexts/ResourceContext";
import ProfileContext from "../../contexts/ProfileContext";

const PerkDetails = () => {
  const [resource, resourceDispatch] = useContext(ResourceContext);
  const [profile, profileDispatch] = useContext(ProfileContext);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  return (
    <div className="xl:ml-80">
      <section className="bg-white p-8 pb-6">
        <div className="flex flex-wrap items-center justify-between pb-6 -mx-2">
          <div className="w-auto px-2">
            <h2 className="text-lg font-semibold">
              <a
                onClick={() =>
                  resourceDispatch({
                    type: "setResource",
                    payload: { type: "Perks" },
                  })
                }
                style={{ cursor: "pointer" }}
              >
                &larr; Back to Perks
              </a>
            </h2>
          </div>
        </div>
        <div className="border-b border-coolGray-100" />
      </section>
      <section className="bg-coolGray-50 py-4">
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -m-3 justify-center">
            <PerkDetailsCard embedId={profile.profile.embed_id} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default PerkDetails;
