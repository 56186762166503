import ManageSubscription from "../ManageSubscription";
import { useAuth } from "../../contexts/AuthProvider";

const ManageSubscriptionView = ({ handleItemClick }) => {
  return (
    <div className="xl:ml-80">
      <section className="bg-coolGray-50 py-4">
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -m-3">
            <ManageSubscription handleItemClick={handleItemClick} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManageSubscriptionView;
