import EditPerkCard from '../EditPerkCard';
import { useEffect } from 'react';

const EditPerkView = () => {

    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []); 

    return (
        <div className='xl:ml-80'>
          <section className='bg-white p-8 pb-6'>
            <div className='flex flex-wrap items-center justify-between pb-6 -mx-2'>
              <div className='w-auto px-2'>
                <h2 className='text-lg font-semibold'>
                    Edit Perk
                </h2>
              </div>
            </div>
            <div className='border-b border-coolGray-100' />
          </section>
          <section className='bg-coolGray-50 py-4'>
            <div className='container px-4 mx-auto'>
              <div className='flex flex-wrap -m-3 justify-center'>
                <EditPerkCard/>
              </div>
            </div>
          </section>
        </div>
    )
}

export default EditPerkView;