import Profile from '../Profile';
import { useAuth } from '../../contexts/AuthProvider';

const ProfileView = () => {
    const { user, profile } = useAuth()

    return (
        <div className='xl:ml-80'>
          <section className='bg-coolGray-50 py-4'>
            <div className='container px-4 mx-auto'>
              <div className='flex flex-wrap -m-3'>
                <Profile profile={profile} user={user}/>
              </div>
            </div>
          </section>
        </div>
    )
}

export default ProfileView;