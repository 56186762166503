const PricingTable = ({ user }) => {
  const handleCheckout = async ({ userId, priceId }) => {
    try {
      const response = await fetch(
        "/.netlify/functions/createCheckoutSession",
        {
          method: "POST",
          body: JSON.stringify({
            user_id: userId,
            price_id: priceId,
            redirect_url: window.location.origin,
          }),
        }
      );
      const session = await response.json();

      // Redirect to the Stripe checkout page
      window.location.href = session.url;
    } catch (error) {
      console.error("Error initiating checkout:", error);
    }
  };

  return (
    <div className="flex flex-wrap justify-center -mx-4">
      <div className="w-full md:w-1/2 lg:w-1/3 p-4">
        <div className="flex flex-col pt-8 pb-8 bg-coolGray-50 rounded-md shadow-md hover:scale-105 transition duration-500">
          <div className="px-8 pb-8">
            <h3 className="mb-6 text-lg md:text-xl text-coolGray-800 font-medium">
              Activity Partnership
            </h3>
            <div className="mb-6">
              <span className="relative -top-10 right-1 text-3xl text-coolGray-900 font-bold">
                $
              </span>
              <span className="text-6xl md:text-7xl text-coolGray-900 font-semibold">
                1,000
              </span>
              <span className="inline-block ml-1 text-coolGray-500 font-semibold">
                per month
              </span>
            </div>
            <p className="mb-6 text-coolGray-400 font-medium">
              Share your product with our founders.
            </p>
            <a
              className="inline-block py-4 px-7 mb-4 w-full text-base md:text-lg leading-6 text-green-50 font-medium text-center bg-violet-500 hover:bg-violet-600 focus:ring-2 focus:ring-violet-500 focus:ring-opacity-50 rounded-md shadow-sm"
              href="#"
              onClick={() =>
                handleCheckout({
                  userId: user.id,
                  priceId: "price_1PnlVxP7sVQbw7036CY3h4yY",
                })
              }
            >
              Subscribe
            </a>
            {/* <a
              className="inline-block py-3 px-7 w-full text-coolGray-500 font-medium text-center bg-white hover:bg-coolGray-100 focus:ring-2 focus:ring-coolGray-100 focus:ring-opacity-50 rounded-md shadow-sm"
              href="#"
            >
              Learn more
            </a> */}
          </div>
          <div className="border-b border-coolGray-100" />
          <ul className="self-start px-8 pt-8">
            <li className="flex items-center mb-3 text-coolGray-500 font-medium">
              <img
                className="mr-3"
                src="flex-ui-assets/elements/pricing/checkbox-violet.svg"
              />
              <span>Over 30,000 founders</span>
            </li>
            <li className="flex items-center mb-3 text-coolGray-500 font-medium">
              <img
                className="mr-3"
                src="flex-ui-assets/elements/pricing/checkbox-violet.svg"
              />
              <span>Real-time notifications</span>
            </li>
            <li className="flex items-center mb-3 text-coolGray-500 font-medium">
              <img
                className="mr-3"
                src="flex-ui-assets/elements/pricing/checkbox-violet.svg"
              />
              <span>Redemption dashboard and analytics</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full md:w-1/2 lg:w-1/3 p-4">
        <div className="flex flex-col pt-8 pb-8 bg-coolGray-50 rounded-md shadow-md hover:scale-105 transition duration-500">
          <div className="px-8 pb-8">
            <div className="flex flex-wrap items-center justify-between mb-6">
              <h3 className="mr-3 text-lg md:text-xl text-coolGray-800 font-medium">
                Leap Partnership
              </h3>
              <span className="inline-block py-px px-2 text-xs leading-5 text-white bg-violet-300 font-medium uppercase rounded-9xl">
                Popular
              </span>
            </div>
            <div className="mb-6">
              <span className="relative -top-10 right-1 text-3xl text-coolGray-900 font-bold">
                $
              </span>
              <span className="text-6xl md:text-7xl text-coolGray-900 font-semibold">
                4,000
              </span>
              <span className="inline-block ml-1 text-coolGray-500 font-semibold">
                per month
              </span>
            </div>
            <p className="mb-6 text-coolGray-400 font-medium">
              Everything in the Activity Partnership, plus:
            </p>
            <a
              className="inline-block py-4 px-7 mb-4 w-full text-base md:text-lg leading-6 text-green-50 font-medium text-center bg-violet-500 hover:bg-violet-600 focus:ring-2 focus:ring-violet-500 focus:ring-opacity-50 rounded-md shadow-sm"
              href="#"
              onClick={() =>
                handleCheckout({
                  userId: user.id,
                  priceId: "price_1PnlWXP7sVQbw703JPrLV5nD",
                })
              }
            >
              Subscribe
            </a>
            {/* <a
              className="inline-block py-3 px-7 w-full text-coolGray-500 font-medium text-center bg-white hover:bg-coolGray-100 focus:ring-2 focus:ring-coolGray-100 focus:ring-opacity-50 rounded-md shadow-sm"
              href="#"
            >
              Learn more
            </a> */}
          </div>
          <div className="border-b border-coolGray-100" />
          <ul className="self-start px-8 pt-8">
            <li className="flex items-center mb-3 text-coolGray-500 font-medium">
              <img
                className="mr-3"
                src="flex-ui-assets/elements/pricing/checkbox-violet.svg"
              />
              <span>Connect directly with Network Members</span>
            </li>
            <li className="flex items-center mb-3 text-coolGray-500 font-medium">
              <img
                className="mr-3"
                src="flex-ui-assets/elements/pricing/checkbox-violet.svg"
              />
              <span>Comprehensive analytics</span>
            </li>
            <li className="flex items-center mb-3 text-coolGray-500 font-medium">
              <img
                className="mr-3"
                src="flex-ui-assets/elements/pricing/checkbox-violet.svg"
              />
              <span>Tailored marketing</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full md:w-1/2 lg:w-1/3 p-4">
        <div className="flex flex-col pt-8 pb-8 bg-coolGray-50 rounded-md shadow-md hover:scale-105 transition duration-500">
          <div className="px-8 pb-8">
            <h3 className="mb-6 text-lg md:text-xl text-coolGray-800 font-medium">
              For Startups Partnership
            </h3>
            <div className="mb-6">
              <span className="relative -top-10 right-1 text-3xl text-coolGray-900 font-bold">
                $
              </span>
              <span className="text-6xl md:text-7xl text-coolGray-900 font-semibold">
                10,000
              </span>
              <span className="inline-block ml-1 text-coolGray-500 font-semibold">
                per month
              </span>
            </div>
            <p className="mb-6 text-coolGray-400 font-medium">
              Everything in the Leap Partnership, plus:
            </p>
            <a
              className="inline-block py-4 px-7 mb-4 w-full text-base md:text-lg leading-6 text-green-50 font-medium text-center bg-violet-500 hover:bg-violet-600 focus:ring-2 focus:ring-violet-500 focus:ring-opacity-50 rounded-md shadow-sm"
              href="#"
              onClick={() =>
                handleCheckout({
                  userId: user.id,
                  priceId: "price_1PnlWsP7sVQbw703JY3WeTID",
                })
              }
            >
              Subscribe
            </a>
            {/* <a
              className="inline-block py-3 px-7 w-full text-coolGray-500 font-medium text-center bg-white hover:bg-coolGray-100 focus:ring-2 focus:ring-coolGray-100 focus:ring-opacity-50 rounded-md shadow-sm"
              href="#"
            >
              Learn more
            </a> */}
          </div>
          <div className="border-b border-coolGray-100" />
          <ul className="self-start px-8 pt-8">
            <li className="flex items-center mb-3 text-coolGray-500 font-medium">
              <img
                className="mr-3"
                src="flex-ui-assets/elements/pricing/checkbox-violet.svg"
              />
              <span>Build your startup engagement program</span>
            </li>
            <li className="flex items-center mb-3 text-coolGray-500 font-medium">
              <img
                className="mr-3"
                src="flex-ui-assets/elements/pricing/checkbox-violet.svg"
              />
              <span>Founder sourcing</span>
            </li>
            <li className="flex items-center mb-3 text-coolGray-500 font-medium">
              <img
                className="mr-3"
                src="flex-ui-assets/elements/pricing/checkbox-violet.svg"
              />
              <span>Featured sponsorship</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    // <section
    //   className="py-20 xl:py-24 bg-white"
    //   style={{
    //     backgroundImage: 'url("flex-ui-assets/elements/pattern-white.svg")',
    //     backgroundPosition: "center",
    //   }}
    // >
    //   <div className="container px-4 mx-auto">
    //     <div className="text-center">
    //       <span className="inline-block py-px px-2 mb-4 text-xs leading-5 text-green-500 bg-green-100 font-medium uppercase rounded-9xl">
    //         Pricing
    //       </span>
    //       <h3 className="mb-4 text-3xl md:text-5xl text-coolGray-900 font-bold tracking-tighter">
    //         Flexible pricing plan for your startup
    //       </h3>
    //       <p className="mb-12 text-lg md:text-xl text-coolGray-500 font-medium">
    //         Pricing that scales with your business immediately.
    //       </p>
    //     </div>

    //   </div>
    // </section>
  );
};

export default PricingTable;
