import Embed from '../Embed';

const EmbedView = () => {

    return (
        <div className='xl:ml-80'>
          <section className='bg-coolGray-50 py-4'>
            <div className='container px-4 mx-auto'>
              <div className='flex flex-wrap -m-3'>
                <Embed/>
              </div>
            </div>
          </section>
        </div>
    )
}

export default EmbedView;