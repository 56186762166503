import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthProvider';
import { useEffect, useState } from 'react';
import { supabase } from '../services/supabase/supabaseClient';

import Loading from '../pages/Loading';

const ProtectedRoute = ({ children }) => {
  const { user } = useAuth()
  // const location = useLocation()
  // const [loading, setLoading] = useState(true)
  // const [hasCompleteProfile, setHasCompleteProfile] = useState(false)

  //   useEffect(() => {
  //     const checkProfile = async () => {
  //         if (!user) return; // Ensure user is available
  //         const { data, error } = await supabase
  //             .from('profiles')
  //             .select('first_name, last_name, organization_name')
  //             .eq('id', user.id)
  //             .single();

  //         if (error) {
  //             console.error('Error fetching profile:', error);
  //             return;
  //         }

  //         if (data && data.first_name && data.last_name && data.organization_name) {
  //             setHasCompleteProfile(true);
  //         }

  //         setLoading(false); // Profile check complete
  //     };

  //     checkProfile();
  // }, [user]);

  // if (loading) {
  //     // Render loading indicator while fetching profile
  //     return <Loading/>
  // }

  if (!user) {
    // user is not authenticated
    return <Navigate to="/login"/>
  }

  // if (!hasCompleteProfile) {
  //   if (location.pathname === '/profile') {
  //     return <>{children}</>
  //   }

  //   return <Navigate to='/profile'/>
  // }

  return <>{children}</>
};

export default ProtectedRoute