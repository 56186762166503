import KPIRow from "../../Analytics/KPIRow";
import ActivityDataTable from "../../Analytics/ActivityDataTable";

const ActivityDashboardView = ({ redemptions }) => {
  const calculateTotalRedemptions = (redemptions) => {
    return redemptions.length;
  };

  const countDistinctEmails = (redemptions) => {
    const uniqueEmails = new Set(
      redemptions.map((redemption) => redemption.email)
    );

    return uniqueEmails.size;
  };

  const calculateTotalRedemptionValue = (redemptions) => {
    const totalValue = redemptions.reduce(
      (acc, curr) => acc + (Number(curr.value) || 0),
      0
    );

    return totalValue.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  return (
    <div className="xl:ml-80">
      <section className="bg-coolGray-50 py-4">
        <div className="container px-4 mx-auto">
          <div className="p-6 h-full border border-coolGray-100 overflow-hidden bg-white rounded-md shadow-dashboard">
            <div className="pb-6 border-b border-coolGray-100">
              <div className="flex flex-wrap items-center justify-between -m-2">
                <div className="w-full md:w-auto p-2">
                  <h2 className="text-coolGray-900 text-lg font-semibold">
                    Activity Dashboard
                  </h2>
                  <p className="text-xs text-coolGray-500 font-medium">
                    View perk redemption activity for your founders.
                  </p>
                </div>
              </div>
            </div>
            {/* <div className='py-6 border-b border-coolGray-100'> */}
            <KPIRow
              data={[
                {
                  title: "Perks Redeemed",
                  value: calculateTotalRedemptions(redemptions),
                },
                {
                  title: "Active Founders",
                  value: countDistinctEmails(redemptions),
                },
                {
                  title: "Redemption Value",
                  value: calculateTotalRedemptionValue(redemptions),
                },
              ]}
            />
            <ActivityDataTable redemptions={redemptions} />
            {/* </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ActivityDashboardView;
