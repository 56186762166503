import { useState, useEffect } from "react";
import { supabase } from "../services/supabase/supabaseClient";
import { useContext } from "react";
import ProfileContext from "../contexts/ProfileContext";
import { useAuth } from "../contexts/AuthProvider";
import PricingTable from "./Stripe/PricingTable";

const Account = () => {
  const { user } = useAuth();
  const [profile, profileDispatch] = useContext(ProfileContext);
  // Instead of isActive, in Account we care whether they HAVE a subscription record
  // If their subscription record is expired, we should still show "Manage Subscription"
  const [subscriptionExists, setSubscriptionExists] = useState(false);
  const [organizationName, setOrganizationName] = useState(
    profile.profile.organization_name || ""
  );
  const [saveButtonText, setSaveButtonText] = useState("Save");

  useEffect(() => {
    if (profile && profile.profile && profile.profile.subscription_end_date) {
      setSubscriptionExists(true);
    }
  }, [profile]);

  const handleManageSubscription = async () => {
    try {
      const response = await fetch(
        "/.netlify/functions/createCustomerPortalSession",
        {
          method: "POST",
          body: JSON.stringify({
            customer_id: profile.profile.stripe_customer_id,
            return_url: window.location.origin,
          }),
        }
      );

      const { url } = await response.json();
      if (url) {
        window.location.href = url; // Redirect to the customer portal
      }
    } catch (error) {
      console.error("Error redirecting to customer portal:", error);
    }
  };

  // const customerPortalUrl =
  //   "https://billing.stripe.com/p/login/test_cN2cQy4fM4OH676fYY";

  return (
    <div className="xl:ml-90" style={{ width: "100%" }}>
      <section className="bg-coolGray-50 py-4">
        <div className="container px-4 mx-auto">
          <div className="p-6 h-full border border-coolGray-100 overflow-hidden bg-white rounded-md shadow-dashboard">
            <div className="pb-6 border-b border-coolGray-100">
              <div className="flex flex-wrap items-center justify-between -m-2">
                <div className="w-full md:w-auto p-2">
                  <h2 className="text-coolGray-900 text-lg font-semibold">
                    Account
                  </h2>
                  <p className="text-xs text-coolGray-500 font-medium">
                    Manage your Launchbook account.
                  </p>
                </div>
                <div
                  className="w-full md:w-auto p-2"
                  style={{ display: subscriptionExists ? "block" : "none" }}
                >
                  <div className="flex flex-wrap justify-between -m-1.5">
                    <div className="w-full md:w-auto p-1.5">
                      <button
                        className="flex flex-wrap justify-center w-full px-4 py-2 bg-violet-500 hover:bg-violet-600 font-medium text-sm text-white border border-violet-500 rounded-md shadow-button"
                        onClick={() => handleManageSubscription()}
                      >
                        <p>Manage Subscription</p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="py-6 border-b border-coolGray-100"
              style={{ display: subscriptionExists ? "block" : "none" }}
            >
              <div className="w-full md:w-9/12">
                <div className="flex flex-wrap -m-3">
                  <div className="w-full md:w-1/3 p-3">
                    <p className="text-sm text-coolGray-800 font-semibold">
                      Subscription type
                    </p>
                  </div>
                  <div className="w-full md:flex-1 p-3">
                    <input
                      className="w-full px-4 py-2.5 text-base text-coolGray-900 font-normal outline-none focus:border-violet-500 border border-coolGray-200 rounded-lg shadow-input"
                      type="text"
                      value={profile.profile.subscription_product_name}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="py-6 border-b border-coolGray-100"
              style={{ display: subscriptionExists ? "block" : "none" }}
            >
              <div className="w-full md:w-9/12">
                <div className="flex flex-wrap -m-3">
                  <div className="w-full md:w-1/3 p-3">
                    <p className="text-sm text-coolGray-800 font-semibold">
                      Subscription expiration
                    </p>
                  </div>
                  <div className="w-full md:flex-1 p-3">
                    <input
                      className="w-full px-4 py-2.5 text-base text-coolGray-900 font-normal outline-none focus:border-violet-500 border border-coolGray-200 rounded-lg shadow-input"
                      type="text"
                      value={new Date(
                        profile.profile.subscription_end_date
                      ).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      })}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full text-center">
              {/* <div className="flex flex-wrap -m-3"> */}
              <div className="w-full p-3">
                <p className="text-sm text-coolGray-800">
                  Need more?{" "}
                  <a
                    className="text-violet-500 hover:text-violet-700"
                    href="https://meetings.hubspot.com/john2497/partnership-discovery"
                  >
                    Schedule a call
                  </a>
                  , or{" "}
                  <a
                    className="text-violet-500 hover:text-violet-700"
                    href="mailto:support@globalventurenetwork.com"
                  >
                    shoot us an email
                  </a>
                  .
                </p>
              </div>
              {/* </div> */}
            </div>
            {!subscriptionExists && <PricingTable user={user} />}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Account;
