import Account from "../Account";
import { useAuth } from "../../contexts/AuthProvider";

const AccountView = () => {
  const { user, profile } = useAuth();

  return (
    <div className="xl:ml-80">
      <section className="bg-coolGray-50 py-4">
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -m-3">
            <Account profile={profile} user={user} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default AccountView;
