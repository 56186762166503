import { createContext, useReducer } from 'react';

const SelectionContext = createContext()

const selectionReducer = (state, action) => {
    switch(action.type) {
        case 'TOGGLE_PERK': {
            return {
                ...state,
                selectedPerks: action.payload.isSelected
                  ? [...state.selectedPerks, action.payload.perk]
                  : state.selectedPerks.filter(perk => perk.id !== action.payload.perk.id),
                deselectedPerks: action.payload.isSelected
                  ? state.deselectedPerks.filter(perk => perk.id !== action.payload.perk.id)
                  : [...state.deselectedPerks, action.payload.perk]
              };
        }
        case 'REMOVE_PERK_SELECTIONS': {
            return {
                ...state,
                selectedPerks: [],
                deselectedPerks: []
            }
        }
        case 'TOGGLE_INVESTOR': {
            return {
                ...state,
                selectedInvestors: action.payload.isSelected
                  ? [...state.selectedInvestors, action.payload.investor]
                  : state.selectedInvestors.filter(investor => investor.id !== action.payload.investor.id),
                deselectedInvestors: action.payload.isSelected
                  ? state.deselectedInvestors.filter(investor => investor.id !== action.payload.investor.id)
                  : [...state.deselectedInvestors, action.payload.investor]
              };
        }
        case 'REMOVE_INVESTOR_SELECTIONS': {
            return {
                ...state,
                selectedInvestors: [],
                deselectedInvestors: []
            }
        }
        case 'TOGGLE_EVENT': {
            return {
                ...state,
                selectedEvents: action.payload.isSelected
                  ? [...state.selectedEvents, action.payload.event]
                  : state.selectedEvents.filter(event => event.id !== action.payload.event.id),
                deselectedEvents: action.payload.isSelected
                  ? state.deselectedEvents.filter(event => event.id !== action.payload.event.id)
                  : [...state.deselectedEvents, action.payload.event]
              };
        }
        case 'REMOVE_EVENT_SELECTIONS': {
            return {
                ...state,
                selectedEvents: [],
                deselectedEvents: []
            }
        }
        default:
            return state
    }
}

export const SelectionContextProvider = (props) => {
    const [selections, selectionsDispatch] = useReducer(selectionReducer, {
        selectedPerks: [],
        deselectedPerks: [],
        selectedInvestors: [],
        deselectedInvestors: [],
        selectedEvents: [],
        deselectedEvents: []
    });

    return (
        <SelectionContext.Provider value={[selections, selectionsDispatch]}>
            {props.children}
        </SelectionContext.Provider>
    )
}

export default SelectionContext;

