import React, { useCallback, useEffect, useState, useContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import ResourceContext from "../contexts/ResourceContext";

import { supabase } from "../services/supabase/supabaseClient";

import Loading from "./Loading";

import PerkList from "../components/PerkList";
import InvestorList from "../components/InvestorList";
import EventList from "../components/EventList";
import PerkDetailsCard from "../components/PerkDetailsCard";
import InvestorDetailsCard from "../components/InvestorDetailsCard";
import EventDetailsCard from "../components/EventDetailsCard";
import Search from "../components/Search";

const types = [
  {
    label: "Perks",
    name: "perks",
    resource_type: "perk",
  },
  {
    label: "Investors",
    name: "investors",
    resource_type: "investor",
  },
  {
    label: "Events",
    name: "events",
    resource_type: "event",
  },
];

const modalStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    position: "relative",
    width: "80%",
    maxHeight: "80vh",
    overflowY: "auto",
    background: "#FFF",
    padding: "20px",
    borderRadius: "8px",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "transparent",
    border: "none",
    fontSize: "1.5rem",
    cursor: "pointer",
  },
};

const Embed = () => {
  const { embed_id } = useParams();
  const [searchParams] = useSearchParams();
  const [resource, resourceDispatch] = useContext(ResourceContext);

  const [activeTab, setActiveTab] = useState("perks");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [keywords, setKeywords] = useState("");

  // Helper to fetch the specific resource by type and ID from Supabase
  const fetchResourceById = async (type, id) => {
    const { data: resource, error } = await supabase
      .from(type)
      .select("*")
      .eq("id", id)
      .single();

    if (error) {
      console.error("Error fetching resource:", error);
      return null;
    }

    return resource;
  };

  const handleSearchChange = (query) => {
    setKeywords(query);
  };

  const fetchAll = useCallback(async () => {
    setIsLoading(true);
    const { data: user, error: userError } = await supabase
      .from("profiles")
      .select("id")
      .eq("embed_id", embed_id)
      .single();

    if (userError) {
      console.error("No records found", userError);
      setIsLoading(false);
      return;
    }

    const { data: typeExcluded, error: typeError } = await supabase
      .from("type_exclusions")
      .select("type")
      .eq("user_id", user.id);

    const availableTypes = types.filter((type) => {
      return !typeExcluded.some((exType) => exType.type === type.name);
    });

    const results = await Promise.all(
      availableTypes.map(async (type) => {
        const { data: resourceExclusions, error: resourceError } =
          await supabase
            .from("resource_exclusions")
            .select("resource_id")
            .eq("user_id", user.id)
            .eq("resource_type", type.resource_type);

        if (resourceError) {
          console.error(
            `Error fetching resource exclusions for ${type.name}`,
            resourceError
          );
          return;
        }

        const excludedResourceIds = resourceExclusions.map(
          (ex) => ex.resource_id
        );

        let query = supabase.from(type.name).select("*");

        switch (type.name) {
          case "perks":
            query = query
              .order("featured", { ascending: false })
              .order("name", { ascending: true });
            break;
          case "investors":
            query = query.order("organization", { ascending: true });
            break;
          case "events":
            // exclude events before today
            const today = new Date().toISOString().split("T")[0];
            query = query.order("name", { ascending: true }).gte("date", today);
            break;
          default:
            break;
        }

        if (excludedResourceIds.length > 0) {
          query = query.not("id", "in", `(${excludedResourceIds.join(",")})`);
        }

        const { data: resources, error: resourcesError } = await query;

        if (resourcesError) {
          console.error(
            `Error fetching resources for ${type.name}`,
            resourcesError
          );
          return;
        }

        return {
          title: type.label,
          type: type.name,
          resources,
        };
      })
    );

    setData(results);
    setIsLoading(false);
  }, [embed_id, types, setData]);

  // Function to handle direct access via share link with query parameters
  const handleDirectAccess = useCallback(async () => {
    const perkId = searchParams.get("perks");
    const eventId = searchParams.get("events");
    const investorId = searchParams.get("investors");

    let resourceType = "";
    let resourceId = "";

    if (perkId) {
      resourceType = "perks";
      resourceId = perkId;
      setActiveTab("perks");
    } else if (eventId) {
      resourceType = "events";
      resourceId = eventId;
      setActiveTab("events");
    } else if (investorId) {
      resourceType = "investors";
      resourceId = investorId;
      setActiveTab("investors");
    }

    if (resourceType && resourceId) {
      const fetchedResource = await fetchResourceById(resourceType, resourceId);
      if (fetchedResource) {
        // Dispatch action to set resource in context
        resourceDispatch({
          type: "setResource",
          payload: { type: resourceType, resource: fetchedResource },
        });
        setShowModal(true); // Open modal
      }
    }
  }, [searchParams, resourceDispatch]);

  const handleCloseModal = () => {
    setShowModal(false);
    // Clean up the URL by removing the query parameters
    const cleanUrl = window.location.pathname; // Get the base URL without query params
    window.history.replaceState(null, "", cleanUrl); // Update the URL in the browser without reloading
  };

  useEffect(() => {
    fetchAll();
    handleDirectAccess();
  }, [fetchAll, handleDirectAccess]);

  const getActiveResources = () => {
    const activeItem = data.find((item) => item.type === activeTab);
    return activeItem ? activeItem.resources : [];
  };

  const handleView = () => {
    setShowModal(true);
  };

  // Filtering resources based on search query
  const filteredResources = getActiveResources().filter((resource) => {
    const nameMatches = resource.name
      .toLowerCase()
      .includes(keywords.toLowerCase());
    const descriptionMatches = resource.description
      .toLowerCase()
      .includes(keywords.toLowerCase());
    return nameMatches || descriptionMatches;
  });

  const TabContent = () => {
    if (isLoading) {
      return <Loading />;
    }

    switch (activeTab) {
      case "investors":
        return (
          <div className="flex flex-wrap">
            <InvestorList
              investors={filteredResources}
              isEmbedded={true}
              handleView={handleView}
            />
          </div>
        );
      case "events":
        return (
          <div className="flex flex-wrap">
            <EventList
              events={filteredResources}
              isEmbedded={true}
              handleView={handleView}
            />
          </div>
        );
      default:
        return (
          <div className="flex flex-wrap">
            <PerkList
              perks={filteredResources}
              isEmbedded={true}
              handleView={handleView}
            />
          </div>
        );
    }
  };

  return (
    <div className="p-10">
      <div className="flex border-b">
        {data.map((item) => (
          <button
            key={item.type}
            className={`py-2 px-4 text-sm font-medium text-gray-600 ${
              activeTab === item.type
                ? "border-b-2 border-indigo-500 text-indigo-600"
                : "hover:text-gray-900"
            } focus:outline-none`}
            onClick={() => setActiveTab(item.type)}
          >
            {item.title}
          </button>
        ))}
      </div>
      <div className="p-4">
        {!isLoading && <Search onSearchChange={handleSearchChange} />}
        <TabContent />
      </div>

      {showModal && (
        <div style={modalStyles.overlay}>
          <div style={modalStyles.content}>
            <button style={modalStyles.closeButton} onClick={handleCloseModal}>
              &times;
            </button>
            {/* <div style={{ overflow: 'visible' }}> */}
            {activeTab === "perks" && <PerkDetailsCard embedId={embed_id} />}
            {activeTab === "events" && <EventDetailsCard embedId={embed_id} />}
            {activeTab === "investors" && (
              <InvestorDetailsCard embedId={embed_id} />
            )}
            {/* </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Embed;
