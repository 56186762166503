import { useEffect, useState } from 'react'
import { useContext } from 'react'
import ProfileContext from '../contexts/ProfileContext'

const Embed = () => {
    const [profile, profileDispatch] = useContext(ProfileContext)
    const [embedId, setEmbedId] = useState(profile.profile.embed_id || '')
    const [organizationName, setOrganizationName] = useState(profile.profile.organization_name || '')
    const [createButtonText, setCreateButtonText] = useState('Create');
    const [embeddedCode, setEmbeddedCode] = useState('')
    const [shareLink, setShareLink] = useState('')


    useEffect(() => { 
      setEmbeddedCode(`<iframe src="${window.location.origin}/embed/${embedId}" title="${organizationName}" width="100%" height="600" frameborder="0" allowfullscreen sandbox="allow-scripts allow-same-origin allow-forms allow-top-navigation allow-top-navigation-by-user-activation"></iframe>`)
      setShareLink(`${window.location.origin}/embed/${embedId}`)
    }, [])
    
    
    // if (!(profile.profile.first_name && profile.profile.last_name && profile.profile.organization_name)) {
    //     return (
    //         <div className='xl:ml-90' style={{width: '100%'}}>
    //         <section>
    //             <div className='max-w-lg w-full m-auto p-8 bg-white rounded-md'>
    //                 <div className='flex items-center justify-center w-10 h-10 mb-4 bg-red-100 rounded-md'>
    //                 </div>
    //                 <h3 className='mb-2 text-2xl font-semibold text-coolGray-900'>
    //                 Profile Missing
    //                 </h3>
    //                 <p className='mb-6 font-medium text-sm text-coolGray-500'>
    //                 Make sure to fill our your profile to create your embed.
    //                 </p>
    //             </div>
    //         </section>
    //         </div>
    //     )
    // }

    return (

      <div className='xl:ml-90' style={{width: '100%'}}>
      <section className='bg-coolGray-50 py-4'>
        <div className='container px-4 mx-auto'>
          <div className='p-6 h-full border border-coolGray-100 overflow-hidden bg-white rounded-md shadow-dashboard'>
            <div className='pb-6 border-b border-coolGray-100'>
              <div className='flex flex-wrap items-center justify-between -m-2'>
                <div className='w-full md:w-auto p-2'>
                  <h2 className='text-coolGray-900 text-lg font-semibold'>
                    Create Embed
                  </h2>
                </div>
                {/* <div className='w-full md:w-auto p-2'>
                  <div className='flex flex-wrap justify-between -m-1.5'>
                    <div className='w-full md:w-auto p-1.5'>
                      <button onClick={handleCreate} className='flex flex-wrap justify-center w-full px-4 py-2 bg-violet-500 hover:bg-violet-600 font-medium text-sm text-white border border-violet-500 rounded-md shadow-button'>
                        <p>{createButtonText}</p>
                      </button>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className='pt-6'>
              <div className='w-full md:w-9/12'>
                <div className='flex flex-wrap -m-3'>
                  <div className='w-full md:w-1/3 p-3'>
                    <p className='text-sm text-coolGray-800 font-semibold'>
                      Embed Code
                    </p>
                    <p className='text-xs text-coolGray-500 font-medium'>
                      Copy this code to embed Launchbook resources in your website. 
                    </p>
                  </div>
                  <div className='w-full md:flex-1 p-3'>
                    <textarea
                      className='block w-full h-64 p-6 text-base text-coolGray-900 font-normal outline-none focus:border-violet-500 border border-coolGray-200 rounded-lg shadow-input resize-none'
                      value={embeddedCode}
                      disabled
                    />
                  </div>
                </div>

                <div className='flex flex-wrap -m-3'>
                  <div className='w-full md:w-1/3 p-3'>
                    <p className='text-sm text-coolGray-800 font-semibold'>
                      Share Link
                    </p>
                    <p className='text-xs text-coolGray-500 font-medium'>
                      Share directly with your founders.
                    </p>
                  </div>
                  <div className='w-full md:flex-1 p-3'>
                    <textarea
                      className='block w-full h-32 p-6 text-base text-coolGray-900 font-normal outline-none focus:border-violet-500 border border-coolGray-200 rounded-lg shadow-input resize-none'
                      value={shareLink}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div> 
    )
}

export default Embed;