import EventCard from './EventCard'

const EventList = ({ events, excludedEvents, isEmbedded, handleView }) => {
  return (
    events.map(event => (
      <EventCard
        key={event.id}
        event={event}
        excluded={excludedEvents?.includes(event.id) || false}
        isEmbedded={isEmbedded}
        handleView={handleView}
      />
    ))
  )
} 

export default EventList;
