import { createContext, useReducer } from 'react';

const ProfileContext = createContext()

const profileReducer = (state, action) => {
    switch(action.type) {
        case 'SET_PROFILE': {
            return action.payload
        }
        default: {
            return null
        }
    }
}

export const ProfileContextProvider = (props) => {
    const [profile, profileDispatch] = useReducer(profileReducer, null)

    return (
        <ProfileContext.Provider value={[profile, profileDispatch]}>
            {props.children}
        </ProfileContext.Provider>
    )
}

export default ProfileContext;