import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useState, useEffect } from 'react'
import { useAuth } from '../contexts/AuthProvider';
import { Navigate, useLocation } from 'react-router-dom';

import Error from '../components/Error'

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Login() {
  const { signUp, signIn, signInWithGoogle } = useAuth()
  
  // see if we should show Create Account
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const createAccountParam = queryParams.get('create_account');

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const [isSignIn, setIsSignIn] = useState(true)

  const [isSignInSuccessful, setSignInSuccessful] = useState(false)

  useEffect(() => {
    if (createAccountParam === 'true') {
      setIsSignIn(false);
    } else {
      setIsSignIn(true);
    }
  }, [createAccountParam]);

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (isSignIn) {
      try {
        const response = await signIn(email, password)
        setSignInSuccessful(true)
      } catch (error) {
        setError(error.message)
        setTimeout(() => {
          setError(null)
        }, 2000);
        console.log('error signing in:', error.message)
      }
    } else {
      try {
        await signUp(email, password);
        setSignInSuccessful(true)
      } catch (error) {
        setError(error.message)
        setTimeout(() => {
          setError(null)
        }, 10000);
        console.error('Error signing up:', error.message);
      }
    }
  }

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
      setSignInSuccessful(true)
    } catch (error) {
      console.error('Error signing up:', error.message);
    }
  }
  

  if (isSignInSuccessful) {
    return <Navigate to="/"/>
  }

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <section
        className='py-24 md:py-32 bg-white'
        style={{
          backgroundImage: 'url("flex-ui-assets/elements/pattern-white.svg")',
          backgroundPosition: 'center',
        }}
      >
        <div className='container px-4 mx-auto'>
          <div className='max-w-sm mx-auto'>
            <div className='mb-6 text-center'>
              <a className='inline-block mb-6' href='#'>
                <img
                  className='h-16'
                  src='flex-ui-assets/logos/Launchbook_Logo.svg'
                  alt=''
                />
              </a>
              <h3 className='mb-4 text-2xl md:text-3xl font-bold'>
                Welcome to Launchbook
              </h3>
              <p className='text-lg text-coolGray-500 font-medium'>
                Empower your founders with perks, investors, and events.
              </p>
            </div>
            <form onSubmit={handleSubmit}> 
              {/* <div className='mb-6'>
                <label
                  className='block mb-2 text-coolGray-800 font-medium'
                  htmlFor=''
                >
                  Name*
                </label>
                <input
                  className='appearance-none block w-full p-3 leading-5 text-coolGray-900 border border-coolGray-200 rounded-lg shadow-md placeholder-coolGray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50'
                  type='name'
                  placeholder='Keaton'
                  value={name}
                  onChange={({ target }) => setName(target.value)}
                  required
                />
              </div> */}
              <div className='mb-6'>
                <label
                  className='block mb-2 text-coolGray-800 font-medium'
                  htmlFor=''
                >
                  Email*
                </label>
                <input
                  className='appearance-none block w-full p-3 leading-5 text-coolGray-900 border border-coolGray-200 rounded-lg shadow-md placeholder-coolGray-400 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-opacity-50'
                  type='name'
                  placeholder='hello@globalventurenetwork.com'
                  value={email}
                  onChange={({ target }) => setEmail(target.value)}
                  required
                />
              </div>
              <div className='mb-4'>
                <label
                  className='block mb-2 text-coolGray-800 font-medium'
                  htmlFor=''
                >
                  Password*
                </label>
                <input
                  className='appearance-none block w-full p-3 leading-5 text-coolGray-900 border border-coolGray-200 rounded-lg shadow-md placeholder-coolGray-400 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-opacity-50'
                  type='password'
                  placeholder='************'
                  value={password}
                  onChange={({ target }) => setPassword(target.value)}
                  required
                />
              </div>
              <div className='flex flex-wrap items-center justify-center mb-6'>
                {/* <div className='w-full md:w-1/2'>
                  <label className='relative inline-flex items-center'>
                    <input
                      className='form-checkbox appearance-none'
                      type='checkbox'
                    />
                    <img
                      className='absolute top-1/2 transform -translate-y-1/2 left-0'
                      src='flex-ui-assets/elements/sign-up/checkbox-icon.svg'
                      alt=''
                    />
                    <span className='ml-7 text-xs text-coolGray-800 font-medium'>
                      Remember me
                    </span>
                  </label>
                </div> */}
                <div className='w-full md:w-auto mt-1'>
                  <a
                    className='inline-block text-xs font-medium text-violet-500 hover:text-violet-600'
                    href='/forgot-password'
                  >
                    Forgot your password?
                  </a>
                </div>
              </div>
              <Error message={error}/>
              <button
                className='inline-block py-3 px-7 mb-4 w-full text-base text-violet-50 font-medium text-center leading-6 bg-violet-500 hover:bg-violet-600 focus:ring-2 focus:ring-violet-500 focus:ring-opacity-50 rounded-md shadow-sm'
                href='#'
                type='submit'
              >
                {isSignIn ? 'Sign In' : 'Create Account'}
              </button>
              {/* <a
                className='inline-flex items-center justify-center py-3 px-7 mb-6 w-full text-base text-coolGray-500 font-medium text-center leading-6 bg-white border border-coolGray-100 hover:border-coolGray-200 rounded-md shadow-sm'
                href='#'
                onClick={handleGoogleSignIn}
              >
                <img
                  className='mr-2'
                  src='flex-ui-assets/elements/sign-up/google-icon-sign-up.svg'
                  alt=''
                />
                <span>Sign in with Google</span>
              </a> */}
              <p className='text-center'>
                <span className='text-xs font-medium'>
                  {isSignIn ? 'Need to create an account? ' : 'Already have an account? '} 
                </span>
                <a
                  className='inline-block text-xs font-medium text-violet-500 hover:text-violet-600 hover:underline'
                  href='#'
                  onClick={() => setIsSignIn(!isSignIn)}
                >
                  {isSignIn ? 'Create Account' : 'Sign in'}
                </a>
              </p>
            </form>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

