import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Loading() {

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <section
        className='py-24 md:py-32 bg-white'
        style={{
          backgroundImage: 'url("flex-ui-assets/elements/pattern-white.svg")',
          backgroundPosition: 'center',
        }}
      >
        <div className='container px-4 mx-auto'>
          <div className='max-w-sm mx-auto'>
            <div className='mb-6 text-center'>
              <a className='inline-block mb-6' href='#'>
                <img
                  className='h-16'
                  src='flex-ui-assets/logos/flex-circle-violet.svg'
                  alt=''
                />
              </a>
              <h3 className='mb-4 text-2xl md:text-3xl font-bold'>
                Loading...
              </h3>
              <p className='text-lg text-coolGray-500 font-medium'>
                Loading...
              </p>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

