import { useState } from "react";

const CopyableTag = ({ embedId, resourceType, resourceId }) => {
  const [label, setLabel] = useState(`Copy ${resourceType} URL`);
  const handleCopy = () => {
    navigator.clipboard.writeText(
      `${window.location.host}/embed/${embedId}?${resourceType}s=${resourceId}`
    ); // Copies the URL of the resource to clipboard
    setLabel("Copied!");
    setTimeout(() => setLabel("Copy URL"), 2000); // Resets the label after 2 seconds
  };

  return (
    <div
      style={{
        marginLeft: "auto",
        paddingTop: "1rem",
        display: "flex",
        gap: "0.5rem",
      }}
    >
      <p
        onClick={handleCopy}
        className="px-3 py-1 text-xs text-violet-500 font-medium border border-violet-500 rounded-full shadow-sm cursor-pointer hover:bg-violet-100"
        style={{ marginBottom: 0 }}
      >
        {label}
      </p>
    </div>
  );
};

export default CopyableTag;
