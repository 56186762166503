import { useContext } from "react";
import ResourceContext from "../contexts/ResourceContext";
import FlagContext from "../contexts/FlagContext";
import HubspotContactForm from "./HubSpotForm";
import CopyableTag from "./CopyableTag";
import ReactMarkdown from "react-markdown";

const InvestorDetailsCard = ({ embedId }) => {
  const [resource, resourceDispatch] = useContext(ResourceContext);
  const [flags, flagDispatch] = useContext(FlagContext);
  const investor = resource.resource;

  const shareLinksFlag = flags?.flags.some(
    (flag) => flag.flag_id === "b8527cc6-0cc0-4675-9ca5-b56dc9e178e0"
  );

  const customComponents = {
    // Overriding of component styles for react-markdown
    p: ({ node, ...props }) => <p style={{ margin: "1em 0" }} {...props} />,
    li: ({ node, ...props }) => (
      <li
        style={{ marginLeft: "1em", counterIncrement: "my-counter" }}
        {...props}
      />
    ),
    ol: ({ node, ...props }) => (
      <ol
        style={{ listStyleType: "decimal", counterReset: "my-counter" }}
        {...props}
      />
    ),
    ul: ({ node, ...props }) => (
      <ul style={{ listStyleType: "disc", paddingLeft: "1em" }} {...props} />
    ),
  };

  return (
    <div
      className="w-full md:w-1/2 p-3 flex"
      style={{ margin: "auto", overflow: "visible" }}
    >
      <div className="bg-white border border-coolGray-100 shadow-dashboard rounded-md">
        <div
          className="flex flex-col px-4 pt-8 pb-6 border-b border-coolGray-100"
          style={{ height: "100%" }}
        >
          {shareLinksFlag && (
            <CopyableTag
              embedId={embedId}
              resourceType={"investor"}
              resourceId={investor.id}
            />
          )}
          <div className="flex justify-center">
            <img
              style={{ paddingTop: "20px" }}
              className="mb-4"
              src={investor.image_url}
              alt=""
            />
          </div>
          <div className="flex justify-center">
            <h2 className="text-lg font-medium text-coolGray-900">
              {investor.organization}
            </h2>
          </div>
          <div>
            <ReactMarkdown components={customComponents}>
              {investor.description}
            </ReactMarkdown>
          </div>
          <div
            className="perk-info"
            style={{
              marginTop: "auto",
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
          >
            <div>
              <h2 style={{ display: "inline-block" }}>
                <strong>Contact:&nbsp;</strong>
              </h2>
              <h2 style={{ display: "inline-block" }}>{investor.name}</h2>
            </div>
            <div>
              <h2 style={{ display: "inline-block" }}>
                <strong>Title:&nbsp;</strong>
              </h2>
              <h2 style={{ display: "inline-block" }}>{investor.title}</h2>
            </div>
          </div>
          <HubspotContactForm
            formId={investor.hubspot_form_id}
            formType="investor"
          />
        </div>
      </div>
    </div>
  );
};

export default InvestorDetailsCard;
