const ContactDataTableRow = ({ name, initials, email, redeemedAt, company, country, founded, teamSize, networkPartner}) => {
    return (
        <tr className="h-18 border-b border-coolGray-100">
            <th className="whitespace-nowrap px-4 bg-white text-left">
                <div className="flex items-center -m-2">
                    <div className="w-auto p-2">
                        <div className="flex items-center justify-center w-10 h-10 text-base font-medium text-violet-600 bg-violet-200 rounded-md">
                            {initials}
                        </div>
                    </div>
                    <div className="w-auto p-2">
                        <p className="text-xs font-semibold text-coolGray-800">{name}</p>
                        <p className="text-xs font-medium text-coolGray-500">{email}</p>
                    </div>
                </div>
            </th>
            <th className="whitespace-nowrap px-4 bg-white text-sm font-medium text-coolGray-800 text-left">{company}</th>
            <th className="whitespace-nowrap px-4 bg-white text-sm font-medium text-coolGray-800 text-left">{redeemedAt}</th>
            <th className="whitespace-nowrap px-4 bg-white text-sm font-medium text-coolGray-800 text-center">{country}</th>
            <th className="whitespace-nowrap px-4 bg-white text-sm font-medium text-coolGray-800 text-left">{founded}</th>
            <th className="whitespace-nowrap px-4 bg-white text-sm font-medium text-coolGray-800 text-left">{teamSize}</th>
            <th className="whitespace-nowrap px-4 bg-white text-sm font-medium text-coolGray-800 text-center">{networkPartner}</th>
            <th className="whitespace-nowrap pr-4 bg-white text-sm font-medium text-coolGray-800">
            </th>
        </tr>
    )
}

export default ContactDataTableRow;