import NetworkPartnerCard from '../../Analytics/NetworkPartnerCard';

const NetworkPartnerDashboardView = ({ submissions, rankings }) => {

    function getNetworkPartnerSummary(submissions) {
      const summary = submissions.reduce((acc, submission) => {
        const id = submission.networkPartnerId;
    
        if (!acc[id]) {
          acc[id] = {
            networkPartnerId: id,
            totalSubmissions: 0,
            organizationName: submission.networkPartner,
            organizationType: submission.networkPartnerType,
            imageUrl: submission.networkPartnerImageUrl,
            networkSize: submission.networkPartnerSize,
            country: submission.networkPartnerCountry,
            state_us_only: submission.networkPartnerState,
            city: submission.networkPartnerCity
          };
        }
    
        acc[id].totalSubmissions++;
    
        return acc;
      }, {});
    
      return Object.values(summary).sort((a, b) => b.totalSubmissions - a.totalSubmissions);
    }

    const summary = getNetworkPartnerSummary(submissions);
    const sortedSummary = summary.sort((a, b) => b.totalSubmissions - a.totalSubmissions);
    // currently limiting global partner rankings to the top 5
    const topRankings = rankings.slice(0, 5);

    return (
      <div className='xl:ml-80'>
        <section className='bg-coolGray-50 py-4'>
          <div className='container px-4 mx-auto'>
            <div className='p-6 h-full border border-coolGray-100 overflow-hidden bg-white rounded-md shadow-dashboard'>
              <div className='pb-6 border-b border-coolGray-100'>
                <div className='flex flex-wrap items-center justify-between -m-2'>
                  <div className='w-full md:w-auto p-2'>
                    <h2 className='text-coolGray-900 text-lg font-semibold'>
                      Network Partners
                    </h2>
                    <p className='text-xs text-coolGray-500 font-medium'>
                      Understand which startup organizations are driving leads.
                    </p>
                  </div>
                </div>
              </div>
              <div className='py-6 border-b border-coolGray-100'>
                <section className="bg-coolGray-50 py-4">
                    <div className="container px-4 mx-auto">
                        <div className="flex flex-wrap -m-3">

                            <div className="w-full md:w-1/2 p-3">
                                <div className="h-full p-6 pb-0 border border-coolGray-100 overflow-hidden bg-white rounded-md shadow-dashboard">
                                    <div className="flex flex-wrap items-center justify-between -m-2">
                                        <div className="w-auto p-2">
                                            <h2 className="text-coolGray-900 text-lg font-semibold">Your Top Partners</h2>
                                        </div>
                                        {/* <div className="w-auto p-2">
                                        <button className="flex flex-wrap justify-center w-full px-4 py-2 font-medium text-sm text-coolGray-500 hover:text-coolGray-600 border border-coolGray-200 hover:border-coolGray-300 rounded-md shadow-button">
                                            <p>View report</p>
                                        </button>
                                        </div> */}
                                    </div>
                                    {sortedSummary.map(partner => (
                                      <NetworkPartnerCard
                                        key={partner.networkPartnerId}
                                        networkPartner={partner.organizationName}
                                        totalSubmissions={partner.totalSubmissions}
                                        networkPartnerType={partner.organizationType}
                                        imageUrl={partner.imageUrl}
                                        networkSize={partner.networkSize}
                                        country={partner.country}
                                        state_us_only={partner.state_us_only}
                                        city={partner.city}
                                      />
                                    ))}
                                </div>
                            </div>

                            <div className="w-full md:w-1/2 p-3">
                                <div className="h-full p-6 pb-0 border border-coolGray-100 overflow-hidden bg-white rounded-md shadow-dashboard">
                                    <div className="flex flex-wrap items-center justify-between -m-2">
                                        <div className="w-auto p-2">
                                            <h2 className="text-coolGray-900 text-lg font-semibold">Global Activity</h2>
                                        </div>
                                        {topRankings.map((partner, index) => (
                                          <NetworkPartnerCard
                                            key={partner.organizationId}
                                            isRankingCard={true}
                                            networkPartner={partner.organizationName}
                                            ranking={index + 1}
                                            networkPartnerType={partner.organizationType}
                                            imageUrl={partner.imageUrl}
                                            networkSize={partner.networkSize}
                                            country={partner.country}
                                            state_us_only={partner.state_us_only}
                                            city={partner.city}
                                          />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
        
    )
}

export default NetworkPartnerDashboardView;