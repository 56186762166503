import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthProvider';
import App from './App';
import { ResourceContextProvider } from './contexts/ResourceContext';
import { SelectionContextProvider } from './contexts/SelectionContext';
import { ProfileContextProvider } from './contexts/ProfileContext';
import { FlagContextProvider } from './contexts/FlagContext';

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ProfileContextProvider>
                <FlagContextProvider>
                    <AuthProvider>
                        <ResourceContextProvider>
                            <SelectionContextProvider>
                                <App />
                            </SelectionContextProvider> 
                        </ResourceContextProvider>
                    </AuthProvider>
                </FlagContextProvider>
            </ProfileContextProvider>
        </BrowserRouter>
    </React.StrictMode>
);
