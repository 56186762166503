import InvestorCard from './InvestorCard'

const InvestorList = ({ investors, excludedInvestors, isEmbedded, handleView }) => {
  return (
    investors.map(investor => (
      <InvestorCard
        key={investor.id}
        investor={investor}
        excluded={excludedInvestors?.includes(investor.id) || false}
        isEmbedded={isEmbedded}
        handleView={handleView}
      />
    ))
  )
} 

export default InvestorList;
