import { useContext, useState, useEffect } from 'react';
import { supabase } from '../../services/supabase/supabaseClient';
import { useAuth } from '../../contexts/AuthProvider';

import SelectionContext from '../../contexts/SelectionContext';

import EventList from '../EventList';
import Toggle from 'react-toggle';
import '../Toggle/Toggle.css'

const Events = () => {
    const [loading, setLoading] = useState(true)
    const [selections, selectionsDispatch] = useContext(SelectionContext)
    const [events, setEvents] = useState([])
    const [excludedEvents, setExcludedEvents] = useState([])
    const [saveButtonText, setSaveButtonText] = useState('Save Selections');
    const [isToggled, setIsToggled] = useState(true)
    const { user } = useAuth()

    useEffect(() => {
      const fetchEvents = async () => {
        try {
          const today = new Date().toISOString().split('T')[0];
          const { data, error } = await supabase.from('events')
            .select('*')
            .order('name', { ascending: true })
            .gte('date', today);
          if (error) {
              throw error
          }

          // Fetch data from the resource_exclusions table using Supabase
          const { data: exclusionData, error: exclusionError } = await supabase
              .from('resource_exclusions')
              .select('resource_id')
              .eq('user_id', user.id)
              .eq('resource_type', 'event') 
          if (exclusionError) {
              throw exclusionError
          }

          const excludedEventIds = exclusionData.map(exclusion => exclusion.resource_id)
          
          setExcludedEvents(excludedEventIds)
          setEvents(data)
        } catch (error) {
          console.error('Error fetching events:', error.message)
      } finally {
          setLoading(false)
      }
      }

      fetchEvents()
    },[selections])

    // Fetch initial toggle value
    useEffect(() => {
      const fetchExclusion = async () => {
        try {
          const { data, error } = await supabase.from('type_exclusions')
            .select('user_id')
            .eq('type','events')
            .eq('user_id', user.id)
            if (data.length !== 0) {
              setIsToggled(false)
            }

          if (error) {
              throw error
          }
        } catch (error) {
          console.error('Error fetching exclusion:', error.message)
        } finally {
            // setLoading(false)
        }
      }

      fetchExclusion()
    },[])

    if (loading) {
      return
    }

    const handleSaveSelections = async () => {
      setSaveButtonText('Saving...');

      const deselectedEvents = selections.deselectedEvents
      const selectedEvents = selections.selectedEvents

      // Insert deselected events into the resource_exclusions table
      for (const event of deselectedEvents) {
        // Check if the event ID is not in the excludedEvents list
        if (!excludedEvents.includes(event.id)) {
            await supabase.from('resource_exclusions').insert([
                { resource_id: event.id, resource_type: 'event' }
            ]);
        }
      }

      // For selected events, remove those rows from resource_exclusions
      for (const event of selectedEvents) {
        // Check if the event ID is in the excludedEvents list
        if (excludedEvents.includes(event.id)) {
            // Delete rows where resource_id is the selected event ID and resource_type is 'event'
            await supabase
                .from('resource_exclusions')
                .delete()
                .eq('resource_id', event.id)
                .eq('resource_type', 'event')
                .eq('user_id', user.id)
        }
      }

      selectionsDispatch({type: 'REMOVE_EVENT_SELECTIONS'})

      setTimeout(() => {
        setSaveButtonText('Save Selections');
      }, 1000);
    };

    const handleToggle = async () => {
      const newToggleValue = !isToggled
  
      if (newToggleValue === true) {
        try {
          const { data, error } = await supabase.from('type_exclusions')
          .delete()
          .eq('user_id', user.id)
          .eq('type', 'events')
  
          setIsToggled(newToggleValue)
        } catch (error) {
          console.error('Error deleting exclusion:', error.message)
        } finally {
  
        }
      }
  
      if (newToggleValue === false) {
        try {
          const { data, error } = await supabase.from('type_exclusions')
          .insert([{ type: 'events' }])
  
          setIsToggled(newToggleValue)
        } catch (error) {
          console.error('Error inserting exclusion:', error.message)
        } finally {
  
        }
      }
    }

    return (
        <div className='xl:ml-80'>
          <section className='bg-white p-8 pb-6'>
            <div className='flex flex-wrap items-center justify-between pb-6 -mx-2'>
              <div className='w-auto px-2'>
                <h2 className='text-lg font-semibold'>
                    Select Events 
                </h2>
              </div>
              <div className='w-auto px-2 flex items-center'>
                <label style={{ display: 'flex', alignItems: 'center' }}>
                  <Toggle
                    defaultChecked={isToggled}
                    icons={false}
                    onChange={handleToggle} 
                  />
                  <span style={{ marginLeft: '8px' }}>Include Events</span>  
                </label>
                <div style={{ marginLeft: '8px' }}></div>
                <button onClick={handleSaveSelections} className='flex flex-wrap justify-center px-4 py-2 bg-violet-500 hover:bg-violet-600 font-medium text-sm text-white border border-violet-500 rounded-md shadow-button'>
                  <p>{saveButtonText}</p>
                </button>
              </div>
            </div>
            <div className='border-b border-coolGray-100' />
          </section>
          <section className='bg-coolGray-50 py-4'>
            <div className='container px-4 mx-auto'>
              <div className='flex flex-wrap -m-3'>
                <EventList events={events} excludedEvents={excludedEvents}/>
              </div>
            </div>
          </section>
        </div>
    )
}

export default Events;