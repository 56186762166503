import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useState } from 'react'
import { supabase } from '../services/supabase/supabaseClient';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function ForgotPassword() {
  const [email, setEmail] = useState('')
  const [buttonText, setButtonText] = useState('Send Reset Link')

  const handleSubmit = async (event) => {
    event.preventDefault()
    await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `https://app.launchbook.com/reset-password`
    })

    setButtonText('Email Sent!')
    setTimeout(() => {
        setButtonText('Send Reset Link')
    }, 2000);
  }

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <section
        className='py-24 md:py-32 bg-white'
        style={{
          backgroundImage: 'url("flex-ui-assets/elements/pattern-white.svg")',
          backgroundPosition: 'center',
        }}
      >
        <div className='container px-4 mx-auto'>
          <div className='max-w-sm mx-auto'>
            <div className='mb-6 text-center'>
              <a className='inline-block mb-6' href='#'>
                <img
                  className='h-16'
                  src='flex-ui-assets/logos/Launchbook_Logo.svg'
                  alt=''
                />
              </a>
              <h3 className='mb-4 text-2xl md:text-3xl font-bold'>
                Forgot your password?
              </h3>
              <p className='text-lg text-coolGray-500 font-medium'>
                Enter your email to receive a reset link.
              </p>
            </div>
            <form onSubmit={handleSubmit}> 
              <div className='mb-6'>
                <label
                  className='block mb-2 text-coolGray-800 font-medium'
                  htmlFor=''
                >
                  Email*
                </label>
                <input
                  className='appearance-none block w-full p-3 leading-5 text-coolGray-900 border border-coolGray-200 rounded-lg shadow-md placeholder-coolGray-400 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-opacity-50'
                  type='name'
                  placeholder='hello@globalventurenetwork.com'
                  value={email}
                  onChange={({ target }) => setEmail(target.value)}
                  required
                />
              </div>
              <button
                className='inline-block py-3 px-7 mb-4 w-full text-base text-violet-50 font-medium text-center leading-6 bg-violet-500 hover:bg-violet-600 focus:ring-2 focus:ring-violet-500 focus:ring-opacity-50 rounded-md shadow-sm'
                href='#'
                type='submit'
              >
                {buttonText}
              </button>
            </form>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

