import KPICard from './KPICard';

const KPIRow = ({ data }) => {
    return (
        <section className="bg-coolGray-50 py-4">
        <div className="container px-4 mx-auto">
            <div className="flex flex-wrap -m-3">
                <KPICard title={data[0].title} value={data[0].value}/>
                <KPICard title={data[1].title} value={data[1].value}/>
                <KPICard title={data[2].title} value={data[2].value}/>
            </div>
        </div>
        </section>
    )
}

export default KPIRow;